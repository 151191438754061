import { Box, Flex, LinkBox } from "@biblioteksentralen/react";
import { isCampaignSeason, isProduction } from "@libry-content/common";
import { DecorativeImage, SommerlesSettings } from "@libry-content/types";
import { ComponentProps, ReactElement, useState } from "react";
import { logClick } from "../../utils/analytics/Plausible";
import { useScreenIsSmall } from "../../utils/hooks/useScreenIsSmall";
import { DefaultContainer } from "../ContentContainer";
import HideServerSide from "../HideServerSide";
import { MissingCampaignTemplateContentBanner } from "../campaigns/MissingCampaignTemplateContentBanner";
import { Edit } from "../editInSanity/EditInSanity";
import { SanityImageFillAvailableSpace } from "../images/SanityImageFillAvailableSpace";
import { BannerContentPrelude } from "./BannerContentPrelude";
import { BannerContentSeparatePage } from "./BannerContentSeparatePage";
import { BannerContentUnderway } from "./BannerContentUnderway";
import Character, { CharacterLayout } from "./Character";
import { ResolvedSommerlesBannerData } from "./sanityQuery";

export type SommerlesBannerProps = {
  sommerles: ResolvedSommerlesBannerData | undefined;
  onLandingPage?: boolean;
};

export const SommerlesBanner = (props: SommerlesBannerProps) => {
  const { sommerles, onLandingPage = false } = props;
  const [backgroundHasLoaded, setBackgroundHasLoaded] = useState(false);
  const smallScreen = useScreenIsSmall();
  const isSeason = sommerles ? isCampaignSeason(sommerles, { applyTeaserStartDate: true }) : false;
  const character1Layout = useCharacter1Layout(isSeason, onLandingPage);
  const character2Layout = useCharacter2Layout(isSeason, onLandingPage);
  const templateContent = sommerles?.templateContent;
  const missingContent = !templateContent?.backgroundImage?.asset;

  if (missingContent) console.error("Missing necessary assets to display sommerles banner");

  if (missingContent && !isProduction) {
    return (
      <MissingCampaignTemplateContentBanner
        campaignName="Sommerles"
        templatePath="/cms/structure/campaigns;sommerlesSettings"
      />
    );
  }

  if (!templateContent) {
    return null;
  }

  const BannerContent = getBannerContent(isSeason, onLandingPage);
  const character2Image = getCharacterImage(isSeason, templateContent, smallScreen, onLandingPage, "2");
  const character1Image = getCharacterImage(isSeason, templateContent, smallScreen, onLandingPage, "1");
  const minHeight = getMinHeight(isSeason, onLandingPage);

  return (
    <DefaultContainer withoutPaddingXBellow="md">
      <LinkBox
        onClick={() => logClick("Sommerlesbanner")}
        textDecoration="none"
        position="relative"
        role="group"
        width="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius={{ base: 0, md: "0.5rem" }}
          overflow="hidden"
          position="relative"
          padding="0"
          minHeight={minHeight}
        >
          <Box position="absolute" width="100%" height="100%">
            <SanityImageFillAvailableSpace
              image={templateContent?.backgroundImage}
              resolution={1000}
              quality={50}
              _groupHover={{}}
              imageWrapperProps={{ borderRadius: 0 }}
              customNextImageProps={{
                onLoad: () => setBackgroundHasLoaded(true),
                style: { filter: "brightness(85%)" },
              }}
            />
            {backgroundHasLoaded && (
              <Box
                position="absolute"
                background="#643166"
                width="100%"
                height="100%"
                top="0"
                left="0"
                opacity="0.33"
              />
            )}
          </Box>
          {backgroundHasLoaded && <BannerContent sommerles={sommerles} smallScreen={smallScreen} />}
        </Flex>
        {/* Since the type of character depends on the screen size we don't render the character on the server,
            to avoid a flash of the character in the wrong size */}
        <HideServerSide>
          {/* Place the characters outside the wrapper in the DOM, so that the wrapper can
          have overflow: hidden (otherwise we get horizontal scroll outside the screen) */}
          <Character zIndex={{ md: 10 }} image={character1Image} {...character1Layout} />
          <Character zIndex={{ md: 10 }} image={character2Image} {...character2Layout} />
        </HideServerSide>
      </LinkBox>
      <Box marginTop="0 !important" zIndex={2}>
        {sommerles && <Edit doc={sommerles} margin="auto" />}
      </Box>
    </DefaultContainer>
  );
};

const getBannerContent = (
  isSeason: boolean,
  onLandingPage: boolean
): ((props: ComponentProps<typeof BannerContentUnderway>) => ReactElement | null) => {
  if (onLandingPage && isSeason) return BannerContentUnderway;
  if (onLandingPage) return BannerContentPrelude;
  return BannerContentSeparatePage;
};

const getCharacterImage = (
  isSeason: boolean,
  templateContent: Partial<SommerlesSettings>,
  smallScreen: boolean,
  onLandingPage: boolean,
  characterIndex: "1" | "2"
): DecorativeImage | undefined => {
  if (smallScreen) return templateContent[`character${characterIndex}ImageSmall`];
  if (onLandingPage && !isSeason) return templateContent[`character${characterIndex}Image`];
  if (onLandingPage) return templateContent[`character${characterIndex}Image`];
  return templateContent[`character${characterIndex}Image3`];
};

/**
 * Set min-height to hard-coded empirical values so that the banner
 * is not short until the background has loaded
 */
const getMinHeight = (isSeason: boolean, onLandingPage: boolean) => {
  if (onLandingPage && isSeason) return { base: "14.8rem", sm: "15.5rem", md: "21.4rem", lg: "21.8rem" };
  if (onLandingPage) return { base: "15.3rem", sm: "16.6rem", md: "20.7rem" };
  return { base: "13.8rem", sm: "16.6rem", md: "19rem" };
};

const useCharacter1Layout = (isSeason: boolean, onLandingPage: boolean): CharacterLayout => {
  if (onLandingPage && isSeason) {
    return {
      width: { base: "25%", sm: "25%", md: "27%", lg: "30%" },
      left: { base: "77%", sm: "78%", md: "-2%", lg: "-10%" },
      top: { base: "40%", sm: "20%", md: "30%", lg: "20%" },
    };
  }

  if (onLandingPage) {
    return {
      width: { base: "30%", sm: "30%", md: "30%", lg: "35%" },
      left: { base: "70%", sm: "70%", md: "-7.5%", lg: "-10%" },
      top: { base: "35%", sm: "10%", md: "30%", lg: "10%" },
    };
  }

  return {
    width: { base: "4.5rem", sm: "5rem", md: "6rem", lg: "9.5rem" },
    bottom: { base: "-2.5rem", sm: "-2.8rem", md: "-2rem" },
    right: { base: "0", sm: "1rem", md: "0.5rem", lg: "2rem" },
  };
};

const useCharacter2Layout = (isSeason: boolean, onLandingPage: boolean): CharacterLayout => {
  if (onLandingPage && isSeason) {
    return {
      width: { base: "20%", sm: "20%", md: "22%", lg: "20%" },
      left: { base: "0%", sm: "0%", md: "79%", lg: "84%" },
      top: { base: "-5%", sm: "-5%", md: "-2%", lg: "-5%" },
    };
  }

  if (onLandingPage) {
    return {
      width: { base: "25%", sm: "25%", md: "22%", lg: "25%" },
      left: { base: "0%", sm: "0%", md: "80%", lg: "78%" },
      top: { base: "-5%", sm: "-5%", md: "-2%", lg: "-5%" },
    };
  }

  return {
    width: { base: "5rem", sm: "6rem", md: "8rem", lg: "12rem" },
    top: { base: "-1rem", sm: "-0.7rem", md: "-2.5rem", lg: "-2rem" },
    left: { base: "0.25rem", sm: "1.5rem", md: "0.5rem", lg: "2rem" },
    transform: { base: "scaleX(-1)", md: "unset" },
  };
};
