import { Box, StyleProps, Text, VisuallyHidden } from "@biblioteksentralen/react";
import { useCountDown } from "../campaigns/useCountDown";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";
import { ResolvedSommerlesBannerData } from "./sanityQuery";

const countdownPlacements: StyleProps[] = [
  {
    top: "65%",
    left: "25%",
  },
  {
    top: "37%",
    left: "40%",
  },
  {
    top: "64%",
    left: "55%",
  },
  {
    top: "37%",
    left: "70%",
  },
];

type Props = {
  sommerles: ResolvedSommerlesBannerData;
  resolution?: number;
  smallScreen: boolean;
};

export const Countdown = ({ sommerles, smallScreen, resolution = 1000 }: Props) => {
  const backgroundImage = sommerles?.templateContent?.countDownBackgroundImage;
  const countdownTo = sommerles?.startDate
    ? new Date(`${new Date().getFullYear()}-${sommerles?.startDate}T00:00:00`)
    : undefined;

  const timeFragments = useCountDown(countdownTo, { smallScreen });
  if (timeFragments.length > 4) console.error("Sommerles countdown is not constructed for >= 1 month");

  if (!sommerles?.startDate || !backgroundImage) return null;

  const reversedFragments = timeFragments.slice(0, 4).reverse();

  return (
    <Box position="relative" fontSize="clamp(.6em, 2.5vw, 1.25em)" width="28em" textAlign="center">
      <SanityImagePreservingAspectRatio image={backgroundImage} resolution={resolution} boxShadow="none" />
      <VisuallyHidden aria-live="polite" aria-atomic aria-relevant="all">
        {reversedFragments
          .slice(0, 3)
          .map(([amount, unit]) => `${amount} ${unit}`)
          .join(", ")}
      </VisuallyHidden>
      <Box aria-hidden>
        {reversedFragments.map(([amount, unit], index) => (
          <Text
            key={index}
            color="#c0c"
            textShadow={`0 0 .1em white`}
            fontWeight={600}
            fontSize={"2.2em"}
            position="absolute"
            alignItems="center"
            lineHeight={1}
            textAlign="center"
            transform="translate(-50%, -50%)"
            {...countdownPlacements[index]}
          >
            <Box>{amount}</Box>
            <Box fontSize={{ base: "0.5em" }}>{unit}</Box>
          </Text>
        ))}
      </Box>
    </Box>
  );
};
