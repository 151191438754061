import { Box, Skeleton } from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";

const Wrapper = ({ isHidden, children }: { isHidden: boolean; children: ReactNode }) => {
  if (isHidden) {
    return (
      // https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#data-nosnippet-attr
      <Box as="div" data-nosnippet opacity="0" aria-hidden>
        {children}
      </Box>
    );
  }

  return <>{children}</>;
};

/**
 * Especially dynamic content (e.g. opening hours and assocated statuses) should not be cached in static rendering,
 * therefore we reserve it for the client side and show loaders on the server.
 * This avoids problems like hydration issues and indexing of dynamic content in search engines.
 */
const HideServerSide = ({ children }: { children: ReactNode }) => {
  const clientSideReady = useClientSideReady();
  return (
    <Skeleton isLoaded={clientSideReady}>
      <Wrapper isHidden={!clientSideReady}>{children}</Wrapper>
    </Skeleton>
  );
};

export default HideServerSide;
