import { Box, HStack, Heading, Text, VStack, colors } from "@biblioteksentralen/react";
import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { SanityImagePreservingAspectRatio } from "../../images/SanityImagePreservingAspectRatio";
import InternalLinkOverlay from "../../InternalLinkOverlay";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";

type BannerContentUnderwayProps = {
  bibliobattles: ResolvedBibliobattlesBannerData;
  smallScreen: boolean;
};

export const BannerContentUnderway = ({ bibliobattles }: BannerContentUnderwayProps) => {
  const { t } = useTranslation();

  if (!bibliobattles?.startDate) return null;

  return (
    <HStack
      zIndex={2}
      justifyContent="center"
      spacing="2rem"
      width="100%"
      padding={{ base: "2rem 0 3rem", sm: "2rem 0.5rem", lg: "2rem" }}
      flexWrap="wrap"
      whiteSpace="nowrap"
    >
      <VStack spacing={{ base: "1rem", lg: "1.5rem" }}>
        <VStack spacing="0rem">
          {/** eslint-disable-next-line i18next/no-literal-string */}
          <Text fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}>{"4. - 10. november"}</Text>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", sm: "2rem", lg: "2.75rem" }}
            marginBottom={{ base: "0", sm: "0.5rem" }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            Bibliobattles
          </Heading>
        </VStack>
        {/* TODO: Expose as a link style variant */}
        <InternalLinkOverlay
          href={getPath(bibliobattles)}
          fontSize={{ base: "md", sm: "lg", lg: "xl" }}
          fontWeight="semibold"
          background="#FFE560"
          color={colors.black}
          textShadow="none"
          _hover={{ transform: "scale(1.05)", transition: ".25s" }}
          transition="1s ease"
          borderRadius="lg"
          padding={{ base: "0.375rem 0.875rem", sm: "0.5rem 1rem" }}
          zIndex={2}
        >
          {t("Bli med")}
        </InternalLinkOverlay>
      </VStack>
      <Box
        aria-hidden
        width="25%"
        filter="drop-shadow(0 .3em 1em black)"
        _groupHover={{ transform: { scale: "1.2" } }} /* FIXME: Doesn't work */
        display={{ base: "none", md: "block" }}
      >
        <SanityImagePreservingAspectRatio
          image={bibliobattles.templateContent?.bannerCharacters}
          resolution={230}
          boxShadow="none"
        />
      </Box>
    </HStack>
  );
};
