import { Box, Flex, LinkBox } from "@biblioteksentralen/react";
import { isCampaignSeason, isProduction } from "@libry-content/common";
import { Pixelify_Sans } from "next/font/google";
import { useState } from "react";
import { logClick } from "../../../utils/analytics/Plausible";
import { useScreenIsSmall } from "../../../utils/hooks/useScreenIsSmall";
import { DefaultContainer } from "../../ContentContainer";
import { Edit } from "../../editInSanity/EditInSanity";
import { SanityImageFillAvailableSpace } from "../../images/SanityImageFillAvailableSpace";
import { MissingCampaignTemplateContentBanner } from "../MissingCampaignTemplateContentBanner";
import { BannerContentPrelude } from "./BannerContentPrelude";
import { BannerContentUnderway } from "./BannerContentUnderway";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";

const ibmPlexMono = Pixelify_Sans({ subsets: ["latin"], weight: "500" });

export type BibliobattlesBannerProps = {
  bibliobattles: ResolvedBibliobattlesBannerData | undefined | null;
};

export const BibliobattlesBanner = ({ bibliobattles }: BibliobattlesBannerProps) => {
  const [backgroundHasLoaded, setBackgroundHasLoaded] = useState(false);
  const smallScreen = useScreenIsSmall();
  const isSeason = bibliobattles ? isCampaignSeason(bibliobattles) : false;
  const templateContent = bibliobattles?.templateContent;
  const missingContent = !templateContent?.bannerBackgroundLandingPage?.asset;

  if (missingContent) console.error("Missing necessary assets to display bibliobattles banner");

  if (missingContent && !isProduction) {
    return (
      <MissingCampaignTemplateContentBanner
        campaignName="Bibliobattles"
        templatePath="cms/structure/campaigns;bibliobattlesTemplateContent"
      />
    );
  }

  if (!templateContent) {
    return null;
  }

  const BannerContent = isSeason ? BannerContentUnderway : BannerContentPrelude;

  // Empirically determined min-heights to avoid jump on load
  const minHeight = isSeason
    ? { base: "12.4rem", sm: "13.1rem", md: "14.5rem", lg: "15.1rem" }
    : { base: "14.1rem", sm: "14.8rem", md: "16.8rem", lg: "17.6rem" };

  return (
    // Headers ovveride font-family unless we specify them using the sx prop
    <DefaultContainer
      withoutPaddingXBellow="md"
      className={ibmPlexMono.className}
      sx={{ "h1,h2": ibmPlexMono.style }}
      minHeight={minHeight}
    >
      <LinkBox
        onClick={() => logClick("Bibliobattlesbanner")}
        textDecoration="none"
        position="relative"
        role="group"
        width="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius={{ base: 0, md: "0.5rem" }}
          overflow="hidden"
          position="relative"
          color="#FFE560"
          textShadow="0px .1em .2em black"
        >
          <Box position="absolute" width="100%" height="100%">
            <SanityImageFillAvailableSpace
              image={templateContent?.bannerBackgroundLandingPage}
              resolution={1000}
              quality={50}
              _groupHover={{}}
              imageWrapperProps={{ borderRadius: 0 }}
              customNextImageProps={{ onLoad: () => setBackgroundHasLoaded(true) }}
            />
          </Box>
          {backgroundHasLoaded && <BannerContent bibliobattles={bibliobattles} smallScreen={smallScreen} />}
        </Flex>
      </LinkBox>
      <Box marginTop="0 !important" zIndex={2}>
        {bibliobattles && <Edit doc={bibliobattles} margin="auto" />}
      </Box>
    </DefaultContainer>
  );
};
