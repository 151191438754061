import { Box, Button, LinkBox, LinkOverlay, Text, VStack } from "@biblioteksentralen/react";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import onDesktop from "../../utils/onDesktop";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";
import { ResolvedSommerlesBannerData } from "./sanityQuery";
import { formatSommerlesDate } from "./utils";
import { ExternalLink } from "react-feather";

type BannerContentUnderwayProps = {
  sommerles: ResolvedSommerlesBannerData;
  smallScreen: boolean;
};

export const BannerContentUnderway = ({ sommerles }: BannerContentUnderwayProps) => {
  const { t, ts, lang } = useTranslation();

  if (!sommerles?.templateContent || !sommerles.startDate || !sommerles.endDate) return null;

  return (
    <VStack
      as={LinkBox}
      justifyContent={{ base: "space-around", md: "space-between" }}
      width="100%"
      padding={{ base: "0.5rem 0 1.5rem", md: "1rem 0 2rem" }}
      spacing={{ base: "0.5rem", md: "1rem" }}
    >
      <Box position="relative" width="75%">
        <SanityImagePreservingAspectRatio
          image={sommerles?.templateContent?.countDownBackgroundImage}
          resolution={1000}
          width="100%"
          boxShadow="none"
        />
        <SanityImagePreservingAspectRatio
          image={ts(sommerles?.templateContent?.logo)}
          resolution={1000}
          boxShadow="none"
          alt="Sommerles"
          position="absolute"
          width="75%"
          top="50%"
          left="50%"
          transform="translate(-50%, -30%)"
        />
      </Box>
      <Text textAlign="center" zIndex={1000} color="white" fontSize={{ base: "md", md: "2xl" }} fontWeight={600}>
        <Box fontSize="1.25em" textTransform="uppercase">
          {t("Bli med")}
        </Box>
        <Box marginTop="-0.25em">
          {formatSommerlesDate(lang, sommerles.startDate)}
          {" - "}
          {formatSommerlesDate(lang, sommerles.endDate)}
        </Box>
      </Text>
      <LinkOverlay href={sommerles?.separatePage ? getPath(sommerles) : sommerles?.templateContent?.url!}>
        <Button
          as="span"
          background="#FBD545"
          color="#433471"
          height="100%"
          marginTop={{ base: "0.5rem", md: "unset" }}
          fontSize={{ base: "md", md: "lg" }}
          padding={{ base: "0.25rem 1rem", md: "0.5rem 1rem" }}
          _hover={{}}
          _groupHover={onDesktop({ filter: "brightness(0.85)" })}
          transition="filter 0.3s ease"
          rightIcon={
            sommerles?.separatePage ? undefined : (
              <Box width="0.75rem" transform="translateY(0.125rem)">
                <ExternalLink width="100%" />
              </Box>
            )
          }
        >
          {t("Les om")} {t("Sommerles")}
        </Button>
      </LinkOverlay>
    </VStack>
  );
};
