/* eslint i18next/no-literal-string: 0 */

import { Box, Link, colors } from "@biblioteksentralen/react";
import { adminSiteUrl } from "@libry-content/common";
import { AlertCircle } from "react-feather";
import { DefaultContainer } from "../ContentContainer";

type MissingCampaignTemplateContentBannerProps = { campaignName: string; templatePath: string };

export const MissingCampaignTemplateContentBanner = ({
  campaignName,
  templatePath,
}: MissingCampaignTemplateContentBannerProps) => (
  <DefaultContainer
    withoutPaddingXBellow="md"
    width="100%"
    height="7rem"
    background={colors.grey10}
    justifyContent="center"
    alignItems="center"
    borderRadius={{ base: undefined, lg: "lg" }}
    padding="0.5rem"
  >
    <Box position="relative" top="50%" transform="translateY(-50%)" textAlign="center">
      <Box display="inline-block" marginRight="0.5rem" transform="translateY(0.25rem)">
        <AlertCircle width="1.25rem" height="1.25rem" />
      </Box>
      Mangler innhold for {campaignName}. Gå til
      <Link margin="0 0.25rem" href={`${adminSiteUrl}${templatePath}`} display="inline">
        Admin-innstillinger for {campaignName}
      </Link>{" "}
      for å sette opp.
    </Box>
  </DefaultContainer>
);
