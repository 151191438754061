import { Box, colors, Heading, Text, VStack } from "@biblioteksentralen/react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";
import { ResolvedSommerlesBannerData } from "./sanityQuery";
import { formatSommerlesDate } from "./utils";

type BannerContentSeparatePageProps = {
  sommerles: ResolvedSommerlesBannerData;
};

export const BannerContentSeparatePage = ({ sommerles }: BannerContentSeparatePageProps) => {
  const { t, ts, lang } = useTranslation();

  if (!sommerles?.templateContent || !sommerles.startDate || !sommerles.endDate) return null;

  return (
    <VStack
      zIndex={2}
      justifyContent={{ base: "space-around", md: "space-between" }}
      width="100%"
      padding={{ base: "1rem 0", sm: "1.5rem 0" }}
      spacing={{ base: "1.5rem", sm: "2rem", md: "0.5rem" }}
    >
      <Heading
        as="h1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        filter={`drop-shadow(0px 0px 5px ${colors.black})`}
        background="linear-gradient(#FFDE47, #FF9B05)"
        backgroundClip="text"
        color="transparent"
      >
        <Text fontSize={{ base: "md", md: "lg" }} as="span">
          {t("Bli med på")}
        </Text>
        <Text
          as="span"
          lineHeight={{ base: "1.5rem", md: "2rem" }}
          marginBottom={{ base: "1rem", md: "1.5rem" }}
          fontSize={{ base: "3xl", md: "4xl" }}
        >
          {t("Sommerles")}
        </Text>
      </Heading>
      <Box maxWidth={{ base: "22rem", sm: "24rem", md: "32rem" }}>
        <SanityImagePreservingAspectRatio
          image={ts(sommerles?.templateContent?.title)}
          resolution={1750}
          boxShadow="none"
          margin={{ base: "-2rem 0 -1.4rem", md: "-1.5rem 0 0" }}
          alt="Fanget i spillet"
        />
      </Box>
      <Text color="#C3FDFD" fontWeight="semibold" fontSize="xl" filter={`drop-shadow(0px 0px 5px ${colors.black})`}>
        {formatSommerlesDate(lang, sommerles.startDate)}
        {" - "}
        {formatSommerlesDate(lang, sommerles.endDate)}
      </Text>
    </VStack>
  );
};
