import { usePrefersReducedMotion } from "@biblioteksentralen/react";
import intervalToDuration from "date-fns/intervalToDuration";
import isBefore from "date-fns/isBefore";
import { useTranslation } from "../../utils/hooks/useTranslation";
import useRerenderInterval from "../../utils/useRerenderInterval";

const timeFragment = (amount: number | undefined, unitSingular: string, unitPlural: string): [number, string] => [
  amount ?? 0,
  amount == 1 ? unitSingular : unitPlural,
];

const safeIntervalToDuration = (...args: Parameters<typeof intervalToDuration>) => {
  try {
    return intervalToDuration(...args);
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const areTimeFragmentsUpToIndexZero = (fragments: [number, string][], index: number) =>
  fragments.slice(0, Math.max(index + 1, 1)).every(([amount]) => amount <= 0);

type Unit = [string, string];

export const useCountDown = (toDate: Date | undefined, { smallScreen } = { smallScreen: false }) => {
  const { t } = useTranslation();
  const now = new Date();
  const prefersReducedMotion = usePrefersReducedMotion();
  useRerenderInterval(1000, prefersReducedMotion || !toDate);

  if (!toDate) return [];

  const units: [Unit, Unit, Unit, Unit, Unit] = [
    [smallScreen ? t("sek") : t("sekund"), smallScreen ? t("sek") : t("sekunder")],
    [smallScreen ? t("min") : t("minutt"), smallScreen ? t("min") : t("minutter")],
    [t("time"), t("timer")],
    [t("dag"), t("dager")],
    [t("måned"), t("måneder")],
  ];

  if (isBefore(toDate, now)) return units.slice(0, 4).map((units) => timeFragment(0, ...units));

  const { months, days, hours, minutes, seconds } = safeIntervalToDuration({
    start: now,
    end: toDate,
  });

  if (
    typeof days === "undefined" ||
    typeof hours === "undefined" ||
    typeof minutes === "undefined" ||
    typeof seconds === "undefined"
  ) {
    return [];
  }

  return [
    timeFragment(seconds, ...units[0]),
    timeFragment(minutes, ...units[1]),
    timeFragment(hours, ...units[2]),
    timeFragment(days, ...units[3]),
    ...(months ? [timeFragment(months, ...units[4])] : []),
  ];
};
