import { StyleProps } from "@biblioteksentralen/react";
import { ComponentProps } from "react";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";

type CharacterProps = Partial<ComponentProps<typeof SanityImagePreservingAspectRatio>>;

export type CharacterLayout = Pick<
  StyleProps,
  "display" | "width" | "top" | "bottom" | "left" | "right" | "transform"
> & {
  _hover?: CharacterProps;
  _groupHover?: CharacterProps;
};

const Character = ({ resolution = 500, ...props }: CharacterProps) => (
  <SanityImagePreservingAspectRatio
    resolution={resolution}
    boxShadow="none"
    position="absolute"
    pointerEvents="none"
    _groupHover={{ transform: "scale(1.05)", transition: "0.4s" }}
    _mediaReduceMotion={{ transform: "none !important" }}
    transition="1s transform"
    {...props}
  />
);

export default Character;
