import React from "react";
import { Text, TextProps } from "@biblioteksentralen/react";
import { ResolvedCustomCampaignBannerData } from "./sanityQuery";
import { formatDateRange } from "../../../utils/date";

type Props = {
  campaign: ResolvedCustomCampaignBannerData;
} & TextProps;

const CustomCampaignDate = ({ campaign, ...chakraProps }: Props) => {
  if (!campaign.startDate || !campaign.endDate) return null;

  const formattedDateRange = formatDateRange(campaign.startDate, campaign.endDate);

  return <Text {...chakraProps}>{formattedDateRange}</Text>;
};

export default CustomCampaignDate;
